<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <a-input v-model:value="stickerSearchName" placeholder="Поиск по названию" @change="searchSticker" allow-clear />
      </div>
      <div class="col-md-9 text-right">
        <vb-create-sticker @reload="reload()" />
      </div>
    </div>
    <vb-edit-sticker
      v-if="editModal"
      :sticker-id="stickerId"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
    >
      <template #text="{ record }">
        <div
          v-if="record.logo_url !== ''"
          :style="getBackground(record.icon)"
          class="sticker-icon"
        ></div>
        <span class="sticker-name">
          {{ record.text }}
        </span>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #type="{ record }">
        {{ record['type_name'] }}
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2" v-if="user.role === 'admin'">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2" v-if="user.role === 'admin'">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, reactive, ref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VbCreateSticker from "@/views/references/sticker/create";
import VbEditSticker from "@/views/references/sticker/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import {message, Modal} from "ant-design-vue";

let
  stickerSearchName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  confirmLoading = ref(false),
  editModal = ref(false),
  timer = null,
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0),
  stickerId = ref(0);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    text: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      key: 'key',
      dataIndex: 'key',
    },
    {
      title: 'Название',
      dataIndex: 'text',
      key: 'text',
      sorter: true,
      slots: {
        customRender: 'text',
      },
    },
    {
      title: 'Тип стикера',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      slots: {
        customRender: 'type',
      },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchSticker = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ text: e.target.value })
    }, 800);
  },
  showEditModal = (id) => {
    stickerId.value = id
    editModal.value = true;
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  deleteModal = (id) => {
    Modal.confirm({
      title: 'Удаление стикера',
      content: `Вы действительно хотите удалить стикер?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`sticker/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Стикер успешно удален')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/sticker/index', { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  getBackground = (url) => {
    return `background-image: url('${url}')`;
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'sticker',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
